/* ---------------STYLING/COLOURS--------------- */
/* COLOUR PALETTE - CURRENTLY DARK BLUE THEME - https://coolors.co/bbbbb4-f85a3e-0a8754-f2fbe0-062726 */
:root {
  --col-bg: #021F3B; /* Dark Blue - Background OLD: #062726 */ 
  --col-font-bright: #F5F5F4; /* Light White - Headings/Text Links */
  --col-font-text: #CACACA; /* Greyish/Silver - Paragraph Text and Unselected Links OLD: #BBBBB4 */
  --col-font-fade: #cacaca5e; /* Dark grey - fade color on hover effect */
  --col-section-highlight: #04437e; /* Light Green - Highlighted Section OLD #OB4745*/
  --col-alt: #ACD8D8; /* Tomato - Heading Standout, Text Link Active, Skill Text OLD #F85A3E*/
  --col-alt-header: #C9AD57;
  --col-alt-secondary: #B68851;
  --col-alt-dark: #1F1F1F ; /* Dark Tomato - Highlight Text Colour, Skill Background OLD #270701 */
}

/* FONT IMPORT */
:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}
html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}
body, html {
  font-family: 'Reddit Mono', Open Sans, Inter, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--col-bg) ;
  color: var(--col-font-text);
}

/* TEXT SELECTION */
::-moz-selection { /* Code for Firefox */
    color: var(--col-alt-dark);
    background-color: var(--col-alt-header); 
}

::selection {
    color: var(--col-alt-dark);
    background-color: var(--col-alt-header); 
}

/* HEADER */
#header-name {
  font-size: 45px;
  letter-spacing: 2px;
  word-spacing: 4px;
  color: var(--col-font-bright) ;
  font-weight: bold;
  font-style: normal;
  text-transform: none;
}
#header-summary {
  font-style: italic;
}
#header-links {
  margin-top: auto;
  font-size: 30px;
  text-align: bottom;
  text-align: left;
}
#header-nav {
  height: 180px;
}
#header-links > a {
  margin-right: 20px;
}
.link-line {
  font-size: 20px;
  letter-spacing: -3px;
  color: var(--col-bg);
  text-decoration: line-through var(--col-font-text);
 }
#header-nav > ul > li > a:hover .link-line {
  color: var(--col-bg);
  text-decoration: line-through var(--col-font-bright);
}

/* HEADER IMAGE */
.image-headshot {
  height: 200px;
  border-radius: 25px;
}

/* LINK PREVIEW IMAGE */
.preview-image {
  width: 100%;
  height: auto;
  outline: 1px solid var(--col-font-text);
  border-radius: 10px;
  filter: grayscale(50%);
}
.image-container {
  position: relative;
}
.link-arrow{
  position: absolute;
  top: 10px;
  right: 10px;
}

/* HYPERLINKS */
a {
  color: var(--col-font-text);
  text-decoration: none;
  transition: 0.3s;
}
a:visited {
  color: var(--col-font-text);
}
a:hover {
  color: var(--col-font-bright);
  cursor: pointer;
}
a:active, a.active-section {
  color: var(--col-font-bright);
}


h3 {
  color: var(--col-font-bright);
  transition: 0
}

/*EXPERIENCE SECTIONS */
.subsection-right > ul {
  list-style-position: inside;
  padding-left: 0;
}
.subsection-right > ul > li {
  padding: 0;
  list-style-type: none;
  padding-bottom: 20px;
}
.experience-description {
  font-style: italic;
}
hr {
  color: var(--col-font-bright);
  border-style:solid;
  margin-top: 30px;
}
#referee-explanation{
  padding: 0 4%;
}

/*SKILL BUTTON COLOUR*/
.skill-button {
  font-size: 14px;
  font-weight: bold;
  color: var(--col-alt);
  background-color: var(--col-alt-dark);
}
.skill-icon {
  color: var(--col-alt-header);
}
.skill-rating {
  color: var(--col-alt-secondary);
}

/*BANNER*/
#banner {
  border: 1px solid yellow;
  position: absolute;
  width: 100%;
  height: 500px;
  margin: auto;
  border-bottom-left-radius: 1000px;
  border-bottom-right-radius: 1500px;
}

/*MAIN DIVS*/
main > div {
  transition: 0.3s;
}
/* HOVER EFFECT FOR WIDE VIEWPORT */
@media (min-width: 769px) { 
  .subsection:hover {
  background-color: var(--col-section-highlight);
  /*outline: 1px solid #ffd500; /* Slightly darker border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 5px 6px 20px -12px rgba(70,99,112,1);
  transition: 0.3s;
  }
  .link-arrow {
    transition: 0.3s;
  }
  .image-container:hover .link-arrow {
  color: var(--col-alt-header);
  transition: 0.3s;
}
  .subsection:hover h3 {
  color: var(--col-alt-header);
  transition: 0.3s;
}
.subsection h3 {
  margin-bottom: 5px;
  transition: 0.3s;
}
  .subsection {
    transition: 0.3s;
  }
  .fade {
    color: var(--col-font-fade);
    /*outline: 1px solid #ffd500; /* Slightly darker border */
    }
  .fade h3 {
    color: var(--col-font-fade);
  }

   /* LINK PREVIEW IMAGE */
   .preview-image {
    filter: grayscale(100%);
    transition: 0.3s;
  }
  .image-container:hover img {
    filter: grayscale(50%);
    transition: 0.3s;
  }
}

  /* ALT COLOUR HYPERLINKS */
  .alt-link {
  color: var(--col-font-bright);
  }
  .alt-link:visited {
  color: var(--col-font-bright);
  }
  .alt-link:hover {
  color: var(--col-alt-header);
  }
  .alt-link:active {
  color: var(--col-alt-header);
  }

 


/*FOOTER*/
footer {
  border-radius: 8px; /* Rounded corners */
  font-size: 10px;
  text-align: center;
}

/* ---------------LAYOUTS/STRUCTURES--------------- */

/* PHONE DIMENSIONS */
@media (max-width: 768px) {
  body, html {
  margin: 0 auto;
  padding: 0;
  height: 100%;
  max-width: 100%;
        overflow: -moz-scrollbars-none; /* Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        -webkit-overflow-scrolling: touch; /* iOS scrolling */
        touch-action: pan-y; 
  }
  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
}
  .App {
    justify-content: center;
    display: flex;
  }
  #container {
    margin: 5vh 0;
    padding: 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  #scroll-container {
    overflow: -moz-scrollbars-none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-overflow-scrolling: touch; /* iOS scrolling */
  }

  #scroll-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
  }
  #left-div {
    width: 100%;
    min-height: 250px;
  }
  #scroll-container {
    width: 100%;
    min-height: 500px;
  }
  header {
    margin-bottom: 30px;
  }
  .persistent-header > #header-name {
    font-size: 35px;
    text-align: center;
  }
  #header-summary {
    padding-top: 20px;
    text-align: justify;
    text-justify: distribute;
    word-spacing: -2px;
  }
  #header-nav {
    display: none;
  }
  #header-links {
    margin-top: 20px;
    padding-left: 10px;
  }
  .res-section
  {
    margin-bottom: 100px;
  }
  .image-headshot {
    float: right;
    margin: 0 0 15px 15px;
  }
  #main-about {
    text-align: justify;
  }

  /*PERSISTENT HEADER STYLING*/

  .persistent-header.scrolled {
    height: 100px;
    background-color: #222;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--col-section-highlight);
    color: var(-col-font-bright);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: 0.1s ease;
    display: flex;
    flex-wrap: wrap;
  }

  .persistent-header.scrolled > #header-name {
    padding-top: 10px;
  }


  .persistent-header.scrolled > #header-summary, .persistent-header.scrolled > #header-links {
    display: none;
  }

  /* PERSISTENT HEADER NAV BAR */
  .persistent-header.scrolled > #header-nav {
    display: contents;
  }
  .persistent-header.scrolled > #header-nav > ul {
    width: 100%;
    list-style-type:none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
  }
  .persistent-header.scrolled > #header-nav > ul > li  {
    padding: 0 8px 0;
    font-size: 14px;
  }
  .persistent-header.scrolled > #header-nav > ul > li > a > .link-line {
    display: none;
  }
  .persistent-header.scrolled > #header-nav > ul > li > .inner-link {
    scroll-margin-top: 10px;
    color: var(--col-font-fade);
  }
  .persistent-header.scrolled > #header-nav > ul > li > .inner-link.active-section, .persistent-header.scrolled > #header-nav > ul > li > .inner-link:hover {
    color: var(--col-font-bright);
  }
  #container:has(.persistent-header.scrolled) .res-section {
    scroll-margin-top: 120px;
  }

  /*SKILL BUTTONS*/
  .skills-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-content: flex-start;
  }
  .skill-button {
    width: 100%;
    display: flex;
    padding: 8px 16px;
    margin: 4px;
    border: none;
    border-radius: 50px; /* Fully rounded corners */
  }
  .skill-rating {
    margin-left: auto;
    min-width: 60px;
  }
  .skill-icon {
    padding-right: 5px;
  }
}

/* LARGE SCREEN DIMENSIONS */
@media (min-width: 769px) {
  html {
    display: grid;
    min-height: 100vh;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0;
  }
  body {
    height: 100%;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }

  /* CURSOR GLOW */
  #cursor {
    position: absolute;
    width: 100px;
    height: 100px;
    filter: blur(100px);
    border-radius: 50%;
    z-index: 0;
    background: rgba(255, 255, 255, 0.1); /* Slightly transparent to make it softer */
    box-shadow: 0 0 450px rgba(255, 255, 255, 0.08), 0 0 450px rgba(255, 255, 255, 0.08), 0 0 250px rgba(255, 255, 255, 0.8);
    pointer-events: none; /* Make sure the cursor doesn't block other interactions */
    transform: translate(-50%, -50%); /* Center the cursor */
    transition: transform 0.1s ease-out; /* Smooth follow effect */
  }

  /*FULL CONTAINER*/
  #container {
    min-width: 1080px;
    width: 80vh;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  
  /*MAIN DIVS*/
  #left-div {
    padding: 8vh 15px;
    width: 40%;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    min-width: 300px;
  }
  #scroll-container {
    width: 60%;
    padding-left: 5vh;
  }
  

  /*HEADER DIVS AND SUBDIV*/
  header {
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  header > div {
    height: 50px;
    width: 100%;
  }
  #header-name{ 
    min-width: 300px;
    padding-bottom: 20px;
  }
  #header-summary {
    margin-bottom: 50px;
    text-align: justify;
  }
  #header-nav > ul {
    list-style-type:none;
  }
  #header-links {
    margin-top: auto;
    height: 33px;
  }
  .link-line {
    font-size: 20px;
    letter-spacing: -3px;
    color: var(--col-bg);
    text-decoration: line-through var(--col-font-text);
    font-weight: 800;
    transition: 0.2s;
  }
  #header-nav > ul > li > a:hover .link-line, .active-section > .link-line{
    color: var(--col-bg);
    text-decoration: line-through var(--col-font-bright);
    transition: 0.2s;
    letter-spacing: 3px;
  }

  /*MAIN DIVS AND SUBDIVS*/
  main {
    width: 100%;
  }
  main > div {
    margin-bottom: 10vh;
    
  }
  #main-about {
    padding: 0 6vh;
    padding-top: 8vh;
    text-align: justify;
  }
  .image-headshot {
    float: right;
    margin: 0 0 15px 15px;
    filter: grayscale(20%);
  }
  .res-section-header{
    padding-left: 3%;
  }
  #about-header {
    display: none; /* Hide about header on full screen.*/
  }
  #main-referees > p {
    padding-left: 25px;
  }

  /* SECTION FADE IN STYLING */
.res-section-header {
  color: var(--col-bg);
  font-size: 20px;
  padding: 5px;
  text-transform: uppercase;
  transition: .5s;
  top: 20px;
  position: relative;
}
.viewed .res-section-header {
  color: var(--col-font-bright);
  top: 0px;
  position: relative;
}
.res-section-inner {
  transition: .5s;
  visibility: hidden;
  right: -100px;
  position: relative;
  opacity: 0;
}
.res-section-inner ul {
  visibility: hidden;
  right: -100px;
  position: relative;
  opacity: 0;
}

.viewed .res-section-inner{
  visibility: visible;
  right: 0;
  position: relative;
  opacity: 100;
}
.viewed .res-section-inner ul{
  visibility: visible;
  right: 0;
  position: relative;
  opacity: 100;
}

  /* SKILL BUTTONS */
  #main-skills > div {
    padding: 0 30px;
  }
  .skills-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-content: flex-start;
  }
  .skill-button {
    min-width: 42%;
    max-width: 42%;
    display: flex;
    padding: 8px 16px;
    margin: 4px;
    border: none;
    border-radius: 50px; /* Fully rounded corners */
  }
  .skill-rating {
    margin-left: auto;
    min-width: 60px;
  }
  .skill-icon {
    padding-right: 5px;
  }
  

  /* SECTION */
  .subsection{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: center;
    width: 100%;
    padding: 0% 4% 4%;;
    box-sizing:border-box;
  }
  .subsection-left{
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 24%;
    box-sizing: border-box;
  }
  .subsection-right{
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 74%;
    padding-left: 1%;
    box-sizing: border-box;
  }
  footer {
    padding-bottom: 8vh;
  }
}